//elements
const $Hamburger = $('#Hamburger'),
    $CloseMenu = $('#CloseMenu'),
    $body = $('body'),
    $openMenu = $('[data-open-menu]'),
    $inView = $('[data-in-view]'),
    $w = $(window);

// variables
const MULTIPLIER = 300; //millis

const initHandlers = () => {
    $openMenu.on('click', menuOpen);
    $CloseMenu.on('click', menuClose);
}

const initInView = () => {
    $inView.on('inview', function (event, isInView) {
        const $this = $(this),
            isAnimate = $this.attr('data-animate');
        if (isAnimate) {
            if (isInView) {
                const animations = $this.data('animate');
                $this.addClass(`vivify ${animations}`).removeClass('paused');
            } else {
                // element has gone out of viewport
                $this.removeClass('vivify');
            }
        }
    });
}

const initMenu = () => {
    if ($('#ShopsMenu').length > 0) {
        const $ShopsMenu = $('#ShopsMenu'),
            $nav = $ShopsMenu.find('ul');

        // get left and run function until it get a left position that is before nav or after nav so there is no overlap
        const getLeft = (elem) => {
            let l = Math.random() * ($w.width() - elem.width());
            return l;
            // console.log(elem.width())
            // if ($w.width() > 768 && l < $nav.position().left - $nav.width() || l > $nav.position().left + $nav.width()) {
            //     console.log('return l')
            //     return l;
            // } else {
            //     getLeft();
            // }
        }
        const getTop = (elem) => {
            console.log($(elem).closest('li').position().top)
            return (Math.random() * ($w.height() - elem.height()) - $(elem).closest('li').position().top);
        }
        const shopResize = () => {
            $ShopsMenu.find('.shop-sign').each(function () {
                let $this = $(this);
                $this.css('left', `${getLeft($this)}px`).css('top', `${getTop($this)}px`);
            });
        }

        $ShopsMenu.find('li').on('mouseover', function () {
            let $this = $(this),
                $figure = $this.find('img');
            $this.find('.shop-sign').css('left', `${getLeft($figure)}px`).css('top', `${getTop($figure)}px`);
        })
        shopResize();
        $w.on('resize', shopResize);
    }

    //detect scroll
    var lastScrollTop = 0;
    $w.scroll(function (event) {
        var st = $(this).scrollTop();
        if (st > lastScrollTop && st > 100) {
            // downscroll code
            $body.addClass('scroll-down')
        } else {
            // upscroll code
            $body.removeClass('scroll-down')
        }
        lastScrollTop = st;
    });
}

const menuOpen = () => {
    $body.addClass('menu-open');
}

const menuClose = () => {
    $body.removeClass('menu-open');
}

const initPlants = () => {
    if ($('.plant').length > 0) {
        $('.plant').each(function () {
            let $this = $(this),
                l = Math.random() * ($w.width() - $this.width()) + $this.width(),
                t = Math.random() * ($body[0].scrollHeight - $this.height()) + $this.height();
            $this.css({ left: l + 'px', top: t + 'px' });
        })
    }



    const addPlant = (e) => {
        if ($body.hasClass('dragging')) return true;
        var attr = $(e.target).data('ignore-click');
        if (typeof attr !== typeof undefined && attr !== false) {
            return true;
        }
        // console.log($(e.target).attr('data-ignore-click').length)
        var len = $(".plant").length;
        var random = Math.floor(Math.random() * len) + 1;
        let $plant = $(".plant").eq(random).clone();
        var min = -10;
        var max = 10;
        var a = Math.floor(Math.random() * (max - min + 1)) + min;

        $body.append($plant);
        $plant.attr('style', '');
        $plant.css({ left: (e.pageX - $plant.width() / 2) + 'px', top: (e.pageY - $plant.height() / 4) + 'px', transform: `rotate(${a % 360}deg)` });


        setTimeout(function () {
            $plant.find('img').removeClass('paused');
        }, 500);

        setTimeout(function () {
            $plant.remove();
        }, 1500);
    }

    //add plant with click
    $w.on('click', addPlant);
}

const initSubmit = () => {
    //if upload form exists
    if ($('#UploadForm').length > 0) {
        $('#UploadInput').on('change', function () {
            if ($('#UploadInput').get(0).files.length === 0) {
                console.log("No files selected.");
            } else {
                console.log('Files added')
                $('#UploadSubmit').prop('disabled', false);
                $('#UploadSubmit').addClass('cursor-pointer');
            }
        })

    }


}

const initMarquee = () => {
    if ($('.marquee').length > 0) {
        $('.marquee').marquee({
            //duration in milliseconds of the marquee
            duration: 15000,
            //gap in pixels between the tickers
            gap: 50,
            //time in milliseconds before the marquee will start animating
            delayBeforeStart: 0,
            //'left' or 'right'
            direction: 'left',
            //true or false - should the marquee be duplicated to show an effect of continues flow
            duplicated: true
        });
    }


}


initHandlers();
initInView();
initMenu();
initPlants();
initSubmit();
initMarquee();

//homepage
if ($body.attr('data-page') === 'home') {
    const $GongSlider = $('#GongSlider'),
        $Time = $('#Time'),
        $ag1 = $('#Agenda1'),
        $ag2 = $('#Agenda2'),
        $ag3 = $('#Agenda3'),
        $ag4 = $('#Agenda4'),
        $ag5 = $('#Agenda5'),
        $ag6 = $('#Agenda6'),
        $ag7 = $('#Agenda7'),
        $ag8 = $('#Agenda8');

    const initTime = () => {
        var hour = $Time.data('hour');
        $('.agenda').removeClass('active');

        switch (true) {
            //welcome
            case hour >= 7 && hour < 8:
                $ag1.addClass('active')
                break;
            //morning shops
            case hour >= 9 && hour < 12:
                $ag2.addClass('active');
                break;
            //lunch
            case hour >= 12 && hour < 13:
                $ag3.addClass('active');
                break;
            //rest hour
            case hour >= 13 && hour < 14:
                $ag4.addClass('active');
                break;
            //afternoon shops
            case hour >= 14 && hour < 18:
                $ag5.addClass('active');
                break;
            //dinner
            case hour >= 18 && hour < 19:
                $ag6.addClass('active');
                break;
            //evening shops
            case hour >= 19 && hour < 22:
                $ag7.addClass('active');
                break;
            //end of day
            case hour >= 22 && hour < 24:
                $ag8.addClass('active');
                break;
        }
        setTimeout(initTime, 60000);

        $('.agenda').animate({ scrollLeft: $('.agenda').find('.active').position().left - 20 })
    }

    const initGallery = () => {
        if ($('[data-show-gallery]').length > 0) {
            let $gallery = $('[data-show-gallery]');
            $gallery.on('click', function () {
                $body.toggleClass('show-gallery');
                $('[data-show-gallery]').text($body.hasClass('show-gallery') ? 'Show Shops' : 'Show Gallery');
            })
        }
    }


    const initGongSlider = () => {
        onGongResize();
        window.addEventListener('resize', onGongResize);
        setTimeout(onGongResize, 500);
        var interval = 0
        function autoplay(run) {
            clearInterval(interval)
            interval = setInterval(() => {
                if (run && slider) {
                    slider.next()
                }
            }, 2000)
        }

        var slider = new KeenSlider('#GongSlider', {
            loop: true,
            duration: 1500,
            mode: "free-snap",
            dragStart: () => {
                autoplay(false)
            },
            dragEnd: () => {
                autoplay(true)
            },
        })

        $GongSlider.on("mouseover", () => {
            autoplay(false)
        }).on("mouseout", () => {
            autoplay(true)
        })
        autoplay(true)
    }

    const onGongResize = () => {
        $GongSlider.width($GongSlider.height() + 3);
        $GongSlider.find('.keen-slider__slide').width($GongSlider.height())
    }

    const initTooltips = () => {
        console.log('init tippy')
        tippy('[data-tippy-content]', {
            // follow on both x and y axes
            followCursor: true,
            theme: 'bucksrock',
            inertia: true,
            hideOnClick: false
        });
    }

    setTimeout(initTime, 1000);
    initGongSlider();
    initGallery();
    initTooltips();
}